<template>
  <div class="border-b border-gray-200 sticky top-16 z-10 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <nav class="-mb-px flex items-center justify-end">
        <button v-if="1 == 2" @click="exportCsv" class="mr-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">{{$t("users.csvExport")}}</button>
        <SearchBar v-model="searchString" :placeholder="$t('search')" classes="py-2 md:max-w-sm" />
      </nav>
    </div>
  </div>
  <div class="mx-auto sm:px-6 lg:px-8">
    <div v-if="users && users.length" class="flex flex-col mt-6">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 pb-60">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 relative">
              <thead class="bg-gray-50 sticky top-0">
                <tr>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{$t("users.name")}}
                  </th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{$t("installation.company")}}
                  </th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{$t("users.tin")}}
                  </th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    email
                  </th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{$t("installation.phoneNumber")}}
                  </th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{$t("service.status")}}
                  </th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   {{$t("users.registrationDate")}}
                  </th>
                  <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{$t("actions")}}
                  </th>
                </tr>
              </thead>
              <tbody v-if="filteredUsers.length">
                <tr v-for="(user, index) in filteredUsers" :key="index" :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                  <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {{ user.name }}
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ user.company }}
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ user.tin }}
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ user.email }}
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ user.phoneNumber }}
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    <span v-if="user.isVerified" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                      {{$t("users.verified")}}
                    </span>
                    <span v-else class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                      {{$t("users.unverified")}}
                    </span>
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ new Date(user.createdAt).toLocaleString('pl-PL') }}
                  </td>

                  <td class="px-4 py-4 space-x-2 whitespace-nowrap text-sm text-gray-500 flex">
                    <a :title="$t('users.sendEmail')" :href="`mailto:${user.email}`" class="rounded-full flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
                      <EnvelopeIcon class="h-5 w-5" aria-hidden="true" />
                    </a>
                    <button :title="$t('edit')" @click="openEditModal(user)" class="rounded-full flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
                      <CogIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button :title="$t('delete')" @click="deleteUser(user.id, user.name)" class="rounded-full flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
                      <TrashIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </td>
                </tr>
              </tbody>
              <p v-else class="text-sm p-4">
                {{$t('users.noUsersFound')}}
              </p>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EditUser
    ref="editUserModal"
    :user="userToEdit"
    @editUser="editUser"
  />
</template>

<script>
import { EnvelopeIcon, TrashIcon, CogIcon } from '@heroicons/vue/24/outline'
import SearchBar from '@/components/inputs/SearchBar.vue'
import EditUser from '@/components/admin/EditUser.vue'
import { API } from 'aws-amplify'
import { api, patchHeaders } from "@/assets/js/api-client.js";
import { mapState } from 'vuex'

export default {
  name: "AdminUsers",
  components: {
    SearchBar,
    EnvelopeIcon,
    TrashIcon,
    CogIcon,
    EditUser
    //rrowLeftIcon,
    //CheckCircleIcon,
  },
  data () {
    return {
      users: null,
      searchString: '',
      userToEdit: null
    }
  },
  computed: {
    ...mapState(['organisation']),
    filteredUsers () {
      let filteredUsers
      if (!this.searchString) {
        filteredUsers = this.users
      }
      else {
        filteredUsers = this.users.filter((user) => {
          const name = user.name && user.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1
          const company = user.company && user.company.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1
          const tin = user.tin && user.tin.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1
          const email = user.email.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1
          const phone = user.phoneNumber && user.phoneNumber.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1
          return name || company || tin || email || phone
        })
      }
      return filteredUsers.sort((a,b) => {
        const timeA = new Date(a.createdAt).getTime()
        const timeB = new Date(b.createdAt).getTime()
        if (timeA < timeB) return 1
        else return -1
      })
    }
  },
  created () {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.$store.dispatch('setLoading', true)

      // users from AWS Cognito
      /*
      API.get('izzifast-installer-app', '/users').then((result) => {
        console.log(result);
        this.users = this.formatUsers(result.Users);
        console.log(this.users)
        if (result.PaginationToken) {
          this.loadMore(result.PaginationToken).then(() => {
            this.$store.dispatch('setLoading', false)
          })
        }
        else {
          this.$store.dispatch('setLoading', false)
        }
      }).catch(err => {
        this.$store.dispatch('setLoading', false)
        console.log(err);
      })
      */

      // users from symfony api

      api.get(`/organisations/${this.organisation}/users`).then(result => {
        console.log(result.data)
        this.users = result.data
        this.$store.dispatch('setLoading', false)
      }).catch(err => {
        this.$store.dispatch('setLoading', false)
        console.log(err);
      })
    },
    formatUsers (awsUsers) {
      if (awsUsers) {
        const users = awsUsers.map((user) => {
          const attributes = {}
          user.Attributes.forEach((attr) => {
            attributes[attr.Name] = attr.Value
          })
          const us = Object.assign({
            organisationId: 1,
            isVerified: 1,
            UserCreateDate: user.UserCreateDate,
            UserStatus: user.UserStatus,
          }, attributes)
          return us
        })
        return users;
      } else return []
    },
    exportCsv () {
      console.log(this.users)
      let rows = []
      const cols = ['organisationId','email', 'roles', 'password', 'phone_number', 'name', 'custom:company', 'custom:tin', 'sub', 'UserCreateDate','isVerified']
      this.users.forEach((user, i) => {
        const row = [i+2]
        cols.forEach(col => {

          row.push(user[col] ? user[col] : '[]')
        })
        rows.push(`"${row.join('","')}"`)
      })
      const csv = rows.join('\n')

      const blob = new Blob([csv], {type: "text/csv;charset=UTF-8"})
      const blobUrl = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.download = `cognito_izzifast_users.csv`;
      a.href = blobUrl;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    deleteUser(id, name) {
      if (confirm(`${this.$t("users.deleteUserText1")} ${name} ${this.$t("users.deleteUserText2")}`)){
        this.$store.dispatch('setLoading', true)

        api.delete(`/users/${id}`).then(result => {
          this.$store.dispatch('setLoading', false)
          console.log(result)
          const index = this.users.findIndex((user) => { return user.id === id })
          this.users.splice(index, 1)
        }).catch(error => {
          console.log(error)
        })
      }
    },
    editUser(e) {
      console.log(e)
      this.$nextTick(() => {
        this.$store.dispatch('setLoading', true)
      })
      api.patch(`/users/${e.id}`, e.params, patchHeaders).then(result => {
        console.log(result)
        this.getUsers()
        this.$store.dispatch('setLoading', false)
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setLoading', false)
      })
    },
    openEditModal (user) {
      this.userToEdit = user
      this.$nextTick(() => {
        this.$refs.editUserModal.show()
      })
    },
    loadMore(paginationToken) {
      return new Promise ((resolve, reject) => {
        const pt = encodeURIComponent(paginationToken)
        API.get('izzifast-installer-app', `/users?pt=${pt}`).then((result) => {
          console.log(result);
          this.users = this.users.concat(this.formatUsers(result.Users));
          if (result.PaginationToken) this.loadMore(result.PaginationToken).then(() => {
            resolve()
          })
          else resolve()
        }).catch(err => {
          console.log(err);
          reject(err)
        })
      })
    }
  },
  watch: {
    organisation () {
      console.log(this.organisation)
      this.getUsers()
    }
  }
};
</script>
