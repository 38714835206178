<template>

        <div :class="classes" class="relative flex justify-between w-full">
              <div class="w-full">
                <label for="search" class="sr-only">{{ $t("search") }}</label>
                <div class="relative">
                  <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                    <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input :value="modelValue" @input="handleInput" id="search" name="search" class="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-ifgreen focus:border-ifgreen sm:text-sm" :placeholder="placeholder" type="search" />
                </div>
              </div>
        </div>


</template>
<script>
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid'


export default {
    props:{
        classes: String,
        modelValue: String,
        placeholder: {
          type: String,
        }
    },
    emits: ['update:modelValue'],
    components: {
        MagnifyingGlassIcon,
    },
    methods: {
        handleInput (e) {
            this.$emit('update:modelValue', e.target.value)
        }
    }
};

</script>
