<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed inset-0 overflow-hidden z-50" @close="open = false" :open="open">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-medium text-gray-900">
                        {{$t("users.editUser")}}
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ifgreen" @click="open = false">
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <p class="mt-6">
                      email: <span class="font-bold">{{ user.email }}</span>
                    </p>
                  </div>
                  <div class="mt-6 relative px-4 sm:px-6">
                    <div class="space-y-2">
                      <InputWithError
                        v-for="attr in Object.keys(userData)"
                        :key="attr"
                        v-model="userData[attr]"
                        id="attr"
                        name="attr"
                        type="text"
                        :label="attrLabels[attr]"
                        :placeholder="attrLabels[attr]"
                        errorText=""
                        :validation="v$.userData[attr]"
                      />
                      <NativeSelect v-if="user.roles[0] !== 'ROLE_SUPERADMIN' && user.id !== currentUser.id" id="role" v-model="role" :label="$t('users.userRole')" :options="roles" :placeholder="$t('choose')" />
                    </div>
                  </div>
                  <div class="mt-6 relative px-4 sm:px-6">
                    <p class="text-sm font-medium text-gray-700 mb-2">Dostępne urządzenia</p>
                    <div class="space-y-1">
                      <div class="flex" v-for="template in deviceTemplates" :key="template.id">
                        <input type="checkbox" :id="`template-${template.id}`" :value="template.id" v-model="availableTemplates" class="rounded text-ifgreen focus:ring-ifgreen">
                        <label :for="`template-${template.id}`" class="ml-2 text-sm text-gray-700">{{template.nameTranslations && template.nameTranslations[$i18n.locale] ? template.nameTranslations[$i18n.locale] : template.name }}</label>
                      </div>
                    </div>
                    <div class="flex mt-4">
                        <input @change="selectAll" ref="selectAll" type="checkbox" id="template-all" class="rounded text-ifgreen focus:ring-ifgreen">
                        <label for="template-all" class="ml-2 text-sm text-gray-700">Wszystkie</label>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="open = false">
                    {{$t('cancel')}}
                  </button>
                  <button @click="editUser" type="submit" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
                    {{$t('save')}}

                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import InputWithError from '@/components/inputs/InputWithError.vue'
import NativeSelect from '@/components/inputs/NativeSelect.vue'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { mapState } from 'vuex'

const phone_format = (value) => {return /^\+[1-9]\d{1,14}$/.test(value)}
const tin_format = (value) => {return /^[0-9]{10}$/.test(value)}

export default {

    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        XMarkIcon,
        InputWithError,
        NativeSelect
    },
    setup () {
      return { v$: useVuelidate() }
    },
    emits: ['edit-user'],
    props: {
      user: Object
    },
    data () {
      return {
        open: false,
        userData: {
          name: "",
          phoneNumber: "",
          company: "",
          tin: ""
        },
        role: null,
        availableTemplates: []
      }
    },
    validations () {
      return {
        userData: {
          name: { required: helpers.withMessage('To pole jest wymagane', required) },
          company: { required: helpers.withMessage('To pole jest wymagane', required) },
          tin: {
            required: helpers.withMessage('To pole jest wymagane', required),
            tin_format: helpers.withMessage('Wprowadź poprawny NIP', tin_format)
          },
          phoneNumber: {
            required: helpers.withMessage('To pole jest wymagane', required),
            phone_format: helpers.withMessage('Numer telefonu musi być postaci +480000000', phone_format)
          }
        }
      }
    },
    computed: {
      ...mapState({currentUser: 'user', deviceTemplates: 'deviceTemplates'}),
      attrLabels () {
        return {
          name: `${this.$t("users.name")}`,
          phoneNumber: `${this.$t("users.phone")}`,
          company: `${this.$t("installation.company")}`,
          tin: `${this.$t("users.tin")}`
        }
      },
      roles () {
        const roles = [
          {name: `${this.$t("users.roleUser")}`, value: 'ROLE_USER'},
          {name: `${this.$t("users.roleAps")}`, value: 'ROLE_ASP'},
          {name: `${this.$t("users.admin")}`, value: 'ROLE_ADMIN'}
        ]
        console.log()
        if (this.currentUser.roles[0] === 'ROLE_SUPERADMIN') roles.push({name: `${this.$t("users.roleProduction")}`, value: 'ROLE_PRODUCTION'})
        return roles
      }
    },
    methods: {
        show () {
          this.userData = {
            name: this.user.name,
            phoneNumber: this.user.phoneNumber,
            company: this.user.company,
            tin: this.user.tin
          }
          if (this.user.roles[0] !== 'ROLE_SUPERADMIN') {
            this.role = this.roles.find(role => {return this.user.roles[0] === role.value}).value
          }
          this.availableTemplates = this.user.availableTemplates.map(template => {return template.split('/')[2]})
          
          this.open = true
          console.log(this.user, this.deviceTemplates)
        },
        selectAll(event) {
          if (event.target.checked) {
            this.availableTemplates = this.deviceTemplates.map(template => {return template.id})
          } else {
            this.availableTemplates = []
          }
        },
        editUser () {

          let data = Object.assign({}, this.userData)
          if (this.role !== null) {
            data['roles'] = [this.role]
          }
          data['availableTemplates'] = this.availableTemplates.map(id => {return `/device_templates/${id}`})

          this.$emit('edit-user', {id: this.user.id, params: data})
          this.open = false
        },
    },
    watch: {
      availableTemplates: {
        handler: function (val) {
          this.$nextTick(() => {
            console.log(this.$refs)
            if (this.$refs.selectAll) this.$refs.selectAll.checked = val.length === this.deviceTemplates.length
          })
        },
        deep: true
      }
    }
}
</script>
